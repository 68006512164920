const base = process.env.REACT_APP_BASE_URL;

const endpoints = {
    auth: {
        signin: base + 'auth/coach-signin',
        signup: base + 'auth/coach-signup',
        resetPassword: (token) => base + 'auth/reset/' + token,
        forgot: base + 'auth/forgot-coach',
    },
    // org: {
    //     add: base + 'add-organization',
    //     edit: (organizationId) =>
    //         base + 'update-organization/' + organizationId,
    //     delete: (organizationId) =>
    //         base + 'delete-organization/' + organizationId,
    //     getAllOrganization: base + 'get-organizations',
    //     getOrganizationDetails: (orgId) =>
    //         base + 'get-single-organization/' + orgId,
    //     getOrganizationUsers: (organizationId) => base + '' + organizationId,
    //     getOrganizationByToken: base + 'get-single-organization-for-org-admin',
    // },
    user: {
        // add: base + 'users/invite-user',
        edit: base + 'users/update-profile/me',
        // delete: (userId) => base + 'users/delete-user/' + userId,
        // getList: base + 'users/all-users',
        profile: base + 'users/get-profile/me',
        // getOrgUsers: (orgId) => base + 'users/get-organization-users/' + orgId,
        // addBulkUsers: (orgId) => base + 'users/upload-csv/' + orgId,
    },
    profile: {
        own: base + 'me',
    },
    dashboard: {
        all: base + 'organization-dashboard',
        orgAdmin: base + 'organization-dashboard-for-orgAdmin',
    },
    imageUpload: {
        profile: base + 'upload-profile-picture',
        profileToken: base + 'upload-files',
    },
    // announcement: {
    //     add: base + 'announcement',
    //     getAll: base + 'notifications',
    // },
    // feedback: {
    //     getFeedbacks: base + 'get-feedbacks',
    // },
    // track: {
    //     getAll: (orgId) => base + 'get-tracks/' + orgId,
    //     editTrack: (trackId) => base + 'update-track/' + trackId,
    //     deleteTrack: (trackId) => base + 'delete-track/' + trackId,
    //     addTrack: base + 'add-track',
    // },
    sessions: {
        addSession: base + 'create-session',
        editSession: (sessionId) => base + 'update-session/' + sessionId,
        getCoachAllSessions: base + 'get-all-session-for-coach',
        getVitalizeAllSessions: base + 'get-all-session',
        getSingleSession: (sessionId) =>
            base + 'get-single-session/' + sessionId,
        deleteSession: (sessionId) => base + 'delete-session/' + sessionId,
    },
    dyteMeeting: {
        addParticipant: base + 'add-participant',
        getUniqueData: (uniqId) => base + 'get-meeting/' + uniqId,
    },
};

export { endpoints };
