export const CREATE_SESSION = 'CREATE_SESSION';
export const DESTROY_SESSION = 'DESTROY_SESSION';

/**
 * Add a user to state
 * @param {*} user
 * @returns
 */
export function createSession(user) {
    return {
        type: CREATE_SESSION,
        user,
    };
}

/**
 * Remove the user from state
 * @returns
 */
export function destroySession() {
    return {
        type: DESTROY_SESSION,
    };
}
