import React from 'react';

const loaderStyle = {
    padding: '2px',
};

const spinnerStyle = (white) => ({
    content: ' ',
    display: 'block',
    background: 0,
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    margin: 0,
    boxSizing: 'border-box',
    border: '2px solid #fff',
    borderColor: !white
        ? '#3B82F6 transparent #3B82F6 transparent'
        : 'white transparent white transparent',
});

function Loader({ white = false }) {
    return (
        <div
            className="flex Loader justify-center"
            style={loaderStyle}
            data-testid="loader">
            <div className="spinner" style={spinnerStyle(white)}></div>
        </div>
    );
}

export { Loader };
