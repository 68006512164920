import React, { useState } from 'react';

export const ToastContext = React.createContext(null);

// a toast can have following things, title = '', message = '', type = ''
export const ToastTypes = {
    danger: 'danger',
    success: 'success',
    warning: 'warning',
    info: 'info',
};

export const ToastProvider = (props) => {
    const [toasts, setToasts] = useState([]);

    const removeToast = (toast) => {
        const newToast = [...toasts];
        newToast.splice(
            newToast.find((t) => t.timestamp === toast.timestamp),
            1
        );
        setToasts(newToast);
    };

    const addToast = (title = '', message = '', type = '') => {
        const toast = { title, message, type, timestamp: new Date().getTime() };
        setToasts([...toasts, toast]);
        setTimeout(() => {
            if (toasts.findIndex((t) => t.timestamp === toast.timestamp)) {
                removeToast(toast);
            }
        }, 3000);
    };

    return (
        <ToastContext.Provider
            value={{
                toasts,
                addToast,
                removeToast,
            }}>
            {props.children}
        </ToastContext.Provider>
    );
};

export const withToast = (Component) => (props) =>
    (
        <ToastContext.Consumer>
            {(toast) => <Component {...toast} {...props} />}
        </ToastContext.Consumer>
    );

export const ToastNotifier = withToast(({ toasts, removeToast }) => {
    const getStyle = (type) => {
        switch (type) {
            case 'danger':
                return 'bg-red-300';
            case 'success':
                return 'bg-green-300';
            case 'info':
                return 'bg-blue-300';
            case 'warning':
                return 'bg-orange-300';
            default:
                return 'bg-green-300';
        }
    };

    return (
        <div className="ToastNotifier">
            {toasts.map((toast) => {
                return (
                    <div
                        className={
                            'toast flex px-4 py-2 mt-2 rounded text-white ' +
                            getStyle(toast.type)
                        }
                        key={toast.timestamp}>
                        <div className="toast-body flex-grow">
                            <div className="toast-title">{toast.title}</div>
                            <div className="toast-message">{toast.message}</div>
                        </div>
                        <div
                            className="toast-delete flex-shrink-0"
                            onClick={() => {
                                removeToast(toast);
                            }}>
                            &times;
                        </div>
                    </div>
                );
            })}
        </div>
    );
});
