export const START_DYTE_SESSION = 'START_DYTE_SESSION';
export const DESTROY_DYTE_SESSION = 'DESTROY_DYTE_SESSION';

/**
 * Add a meeting to state
 * @param {*} meeting
 * @returns
 */
export function startDyteMeeting(meeting) {
    console.log(meeting);
    return {
        type: START_DYTE_SESSION,
        meeting,
    };
}

/**
 * Remove the meeting from state
 * @returns
 */
export function destroyDyteMeeting() {
    return {
        type: DESTROY_DYTE_SESSION,
    };
}
