import React, { Suspense, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import { ToastNotifier, withToast } from './contexts/toastr.context';
import { Loader } from './_components/loader.component';
import { UserService } from './_service/user.service';
import { createSession, destroySession } from './_store/_actions/auth.actions';
import { history } from '../src/_config';

const AuthApp = React.lazy(() => import('./_components/auth-app.component'));

function AppComponent({ user, setUser, addToast, removeUser }) {
    const dispatch = useDispatch();
    const token = JSON.parse(localStorage.getItem('token'));

    useEffect(() => {
        getMyProfile();
    }, [token]);

    const getMyProfile = async () => {
        if (token) {
            UserService.getUserProfile(token)
                .then((response) => {
                    // save the user
                    localStorage.setItem(
                        'user',
                        JSON.stringify({
                            token: token,
                            email: response.email
                                ? response.email
                                : response.email,
                            name: response.name,
                            role: response.roles[0] ? response.roles[0] : null,
                            id: response._id,
                        })
                    );

                    setUser({
                        token: token,
                        email: response.email ? response.email : response.email,
                        name: response.name,
                        role: response.roles[0] ? response.roles[0] : null,
                        id: response._id,
                    });
                })
                .catch(async (error) => {
                    if (
                        error &&
                        error.response &&
                        (error.response.data.message ===
                            "Cannot read property 'user' of null" ||
                            error.response.data.message ===
                                'JWT Token is required' ||
                            error.response.data.message ===
                                'invalid signature' ||
                            error.response.data.message ===
                                'Authentication error!' ||
                            error.response.data.message ===
                                'invalid algorithm' ||
                            error.response.data.message === 'jwt expired')
                    ) {
                        // addToast(error.response.data.message, "", ToastTypes.danger);
                        removeUser();
                        localStorage.clear();
                        setTimeout(() => {
                            history.push('/signin');
                        }, 1500);
                    }
                });
        }
    };

    return (
        <div className="App">
            <Suspense fallback={<Loader />}>
                <AuthApp user={user} />
            </Suspense>
            <ToastNotifier />
        </div>
    );
}

const dtp = (dispatch) =>
    bindActionCreators(
        {
            setUser: createSession,
            removeUser: destroySession,
        },
        dispatch
    );

const App = withToast(
    connect(
        (state) => ({
            user: state.auth,
        }),
        dtp
    )(AppComponent)
);

export default App;
