import axios from 'axios';

export function configureAxios() {
    axios.interceptors.response.use(undefined, function (error) {
        if (error.response.status === 401) {
            // clear the session
            localStorage.removeItem('pensil.user');
            // redirect to main app to clear the session there as well
            const port = window.location.port;
            window.location.href =
                process.env.REACT_APP_CLIENT_URL +
                (port ? ':' + port : '') +
                '?action=logout&redirect=' +
                window.location.origin;
            // console.log("API FAILED WITH 401", { error });
        }
        return Promise.reject(error);
    });
}
