import {
    START_DYTE_SESSION,
    DESTROY_DYTE_SESSION,
} from '../_actions/dyte.action';

/**
 * Main Dyte Reducer
 * @param {*} state
 * @param {*} action
 * @returns
 */
export function dyteReducer(state = null, action) {
    switch (action.type) {
        case START_DYTE_SESSION:
            return action.meeting;
        case DESTROY_DYTE_SESSION:
            return null;
        default:
            return state;
    }
}
